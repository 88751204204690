@charset "utf-8";

/* system typefaces */
$serif      : Georgia, Times, serif;
$sans-serif : -apple-system,BlinkMacSystemFont,Helvetica Neue,sans-serif,PingFang SC,Microsoft YaHei,Source Han Sans SC,Noto Sans CJK SC,WenQuanYi Micro Hei;
$monospace  : Monaco, Consolas, "Lucida Console", monospace;

/* type scale */
$type-size-1 : 2.441em;  // ~39.056px
$type-size-2 : 1.953em;  // ~31.248px
$type-size-3 : 1.563em;  // ~25.008px
$type-size-4 : 1.25em;   // ~20px
$type-size-5 : 1em;      // ~16px
$type-size-6 : 0.75em;   // ~12px
$type-size-7 : 0.6875em; // ~11px
$type-size-8 : 0.625em;  // ~10px

/* From https://mmistakes.github.io/minimal-mistakes/docs/stylesheets/#syntax-highlighting */
/* default syntax highlighting styling (base16) */
$base00: #263238;
$base01: #2e3c43;
$base02: #314549;
$base03: #546e7a;
$base04: #b2ccd6;
$base05: #eeffff;
$base06: #eeffff;
$base07: #ffffff;
$base08: #f07178;
$base09: #f78c6c;
$base0a: #ffcb6b;
$base0b: #c3e88d;
$base0c: #89ddff;
$base0d: #82aaff;
$base0e: #c792ea;
$base0f: #ff5370;

@import "minimal-mistakes/skins/neon"; // skin
@import "minimal-mistakes"; // main partials

/* Use same styling as syntax highlighting */
p> code {
  background: #263238;
}

.page {
  @include breakpoint($x-large) {
    width: calc(100% - #{$right-sidebar-width-narrow});
    padding-right: $right-sidebar-width-narrow;
  }
}

.page__footer {
  margin-top: 0.5rem;
}

.page__content {
  font-size: 17px;
}

::-moz-selection {
  color: #000;
  background: #fff;
}

::selection {
  color: #000;
  background: #fff;
}

.layout--events .initial-content {
  background-image: url("/assets/blue-background.jpg");
  background-size: 100% 100%;
}

.layout--events .page__content {
  font-size: 16px;
}

table {
  font-size: 15px;
}

.awards__info {
  margin-left: 15px;
  margin-bottom: 20px;
  i {
    margin-right: 4px;
  }
}

.events__list >div {
  margin-top: 25px;
  margin-bottom: 25px;
}

.events__title {
  font-size: 1.25em;
  line-height: 1.2;
  font-weight: bold;
  margin-bottom: 5px;

  i {
    margin-right: 10px;
  }
}

.events__date {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.8em;
  margin-left: 10px;
  span >i {
    margin-left: 8px;
  }
}

.member__list {
  margin-bottom: 2em;
  @include breakpoint($small) {
    display: flex;
    flex-wrap: wrap;
  }

  > div {
    margin: 0.4em;
    height: 6.5em;
    border: 1px solid #FFF;
    border-radius: 4px;
    @include breakpoint($small) {
      margin: 0.2em;
      width: calc(33.3333% - 0.4em);
    }
  }
}

.member__avatar {
  display: table-cell;
  vertical-align: top;

  img {
    margin: 15px 10px 3px 15px;
    max-width: 60px;
    border-radius: 10%;
    border: 1px solid $border-color;
  }
}

.member__links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: 5px;

  a {
    color: #FFF;
  }
}

.member__content {
  display: table-cell;
  vertical-align: top;
  padding-left: 5px;
  padding-right: 5px;
  line-height: 1;
}

.member__info {
  margin: 8px 0 3px 0;
  width: 100%;
  i {
    vertical-align: middle;
    font-size: 0.1em;
    margin: 0;
  }
}

.member__name {
  line-height: 1.2;
  font-weight: bold;
  font-size: 1em;
  margin-right: 10px;
}

.member__label {
  span {
    margin-right: 5px;
    padding: 0.2em;
    font-weight: bold;
    text-align: center;
    color: #fff;
    font-size: 0.6em;
    border-radius: 3px;
  }
  span:nth-child(1) { /* major */
    background-color: #0a95ff;
  }
  span:nth-child(2) { /* year */
    background-color: #3fa63f;
  }
}

.member__bio {
  margin-top: 8px;

  p {
    margin: 0;
    line-height: 1.5;
    font-size: 13px;
  }
}